.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
  .ExcelTable2007 {
      border: 1px solid #B0CBEF;
      border-width: 1px 0px 0px 1px;
      font-size: 11pt;
      font-family: Calibri;
      font-weight: 100;
      border-spacing: 0px;
      border-collapse: collapse;
      padding: 10px;
  }
  
  .ExcelTable2007 TD {
      border: 0px;
      background-color: white;
      padding: 0px 4px 0px 2px;
      border: 1px solid #D0D7E5;
      border-width: 0px 1px 1px 0px;
  }
  
  .ExcelTable2007 TD B {
      border: 0px;
      background-color: white;
      font-weight: bold;
  }
  
  .ExcelTable2007 TD.heading {
      background-color: #E4ECF7;
      text-align: center;
      border: 1px solid #9EB6CE;
      border-width: 0px 1px 1px 0px;
      font-weight: bold;
  }
  
  
  TH {
      text-align: center;
  }
  
  
  .jumbotron-button {
      color: #fff !important;
      background-color: transparent !important;
      border-color: lightgrey !important;
  }
  
  .jumbotron-button:hover {
      color: #fff !important;
      background-color: #5a6268 !important;
      border-color: #545b62 !important;
  }
  
  .restrict-card {
      height: 250px;
      overflow-y: overlay;
  }
  
  