/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {

}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
  border: 1px solid #bbbbbb;
  text-align: left;
  padding: 8px;
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: #bbbbbb;
  border-width: 1px 1px 0 1px;
  border-style: solid;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

.contact-us{
  background: linear-gradient(to right, #4286f4 40%, #42e2f4 100%);
  background-image: linear-gradient(to right, rgb(66, 134, 244) 40%, rgb(0, 0, 0) 100%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: cover;
  background-attachment: fixed;
  position: relative; 
  
}

.contact-us.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  /* font-weight:  */
}
 

body {
  background-color: #FFCC2A;
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace; */
}

/* [class*="col-"] {
  padding-right: 2px;
  padding-left: 2px;
  margin-right: 5px;
  margin-left: 5px;
} */

@media all and (display-mode: standalone) {
  body {
    background-color: "#eee";
  }
}
.nav {
  text-decoration: none;
  border: none;
  text-align: center;
  color: white;
  list-style-type: none;
  padding: 7px;
}

.nav:hover {
  text-decoration: none;
  color: white;
}

.active {
  background-color: #974513;
  border: 0.2px solid #f8f8f8;
  /* padding: .5em 1em; */
  border-radius: 0.5rem;
}

.module-pic {
  display: none;
}

.nav-right {
  float: left;
}

.spcnm::-webkit-inner-spin-button,
.spcnm::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 1024px) {
  .module-pic {
    display: inline;
  }
}

.pointer {
  cursor: pointer !important;
}

.login-form {
    width: '100%';
    max-width: 400px;
    padding: 15px;
    margin: auto;
    height: '100%';
    padding-top: 15vh;
}

.sub {
    padding: 10px;
}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  
  .ExcelTable2007 {
      border: 1px solid #B0CBEF;
      border-width: 1px 0px 0px 1px;
      font-size: 11pt;
      font-family: Calibri;
      font-weight: 100;
      border-spacing: 0px;
      border-collapse: collapse;
      padding: 10px;
  }
  
  .ExcelTable2007 TD {
      border: 0px;
      background-color: white;
      padding: 0px 4px 0px 2px;
      border: 1px solid #D0D7E5;
      border-width: 0px 1px 1px 0px;
  }
  
  .ExcelTable2007 TD B {
      border: 0px;
      background-color: white;
      font-weight: bold;
  }
  
  .ExcelTable2007 TD.heading {
      background-color: #E4ECF7;
      text-align: center;
      border: 1px solid #9EB6CE;
      border-width: 0px 1px 1px 0px;
      font-weight: bold;
  }
  
  
  TH {
      text-align: center;
  }
  
  
  .jumbotron-button {
      color: #fff !important;
      background-color: transparent !important;
      border-color: lightgrey !important;
  }
  
  .jumbotron-button:hover {
      color: #fff !important;
      background-color: #5a6268 !important;
      border-color: #545b62 !important;
  }
  
  .restrict-card {
      height: 250px;
      overflow-y: overlay;
  }
  
  
